import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'


import Pic3 from "../../Utils/ODISSI/Testimonials/Pic-3.jpg";
import Dum2 from "../../Utils/ODISSI/Testimonials/Pic-2.jpg";
import "./Testimonial.css";
import { useState } from "react";

const Testimonial = () => {
  const testi1 = {
    src: Pic3,
    name: "Sai Raghav",
    description:
    "I can't express how much I adore ICONIC dance studio! Balaraju Sir's choreography and dance steps are just incredible. I enthusiastically rate it 5 stars because every class is an exciting adventure. Dancing here not only makes me happy but also helps me improve. I'm hooked and couldn't ask for a better place to dance my heart out!"
,
  };
  const testi2 = {
    src: Dum2,
    name: "Jaspreet Parida",
    description:
    "ICONIC dance studio is an absolute gem! Balaraju Sir's exceptional choreography and vibrant teaching style make every class a joyful experience. The added emphasis on fitness is a fantastic bonus. The studio's supportive community has not only enriched my dance journey but also led to lifelong friendships. I'm grateful for the holistic and unforgettable experience that ICONIC provides",
  };
  
  
  const [testimonials, setTestimonials] = useState([
    testi1,
    testi2,
   
    
  ]);

  const styles = [ "ecllip2", "ecllip3", "ecllip4"];

  const leftClicked = () => {
    setTestimonials((testi) => {
      const lastTesti = testi[testi.length - 1];
      const updatedTesti = [lastTesti, ...testi.slice(0, testi.length - 1)];
      return updatedTesti;
    });
  };
  
  const rightClicked = () => {
    setTestimonials((testi) => {
      const firstTesti = testi[0];
      const updatedTesti = [...testi.slice(1), firstTesti];
      return updatedTesti;
    });
  };
  

  const mainControls = useAnimation();
  const slideControls = useAnimation();

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      slideControls.start("visible");
      mainControls.start("visible");
    } else {
      slideControls.start("hidden");
      mainControls.start("hidden");
    }
  }, [isInView, mainControls, slideControls]);

  
  return (
    <div className="RussoOne  max500:h-[20rem] max700:h-[50rem] size h-[45.8rem]  z-10">
    <div className="back-img py-[4rem] flex flex-col item-center justify-center w-full  ">
    <div ref={ref} style={{ position: "relative", overflow: "hidden" }}>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: -75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 0.5, delay: 0.25 }}
      >
      <h1 className=" Test-text text-white-250 max478:text-white-[4rem]">
        TESTIMONIAL
      </h1>
      
      </motion.div>
      <motion.div
        variants={{
          hidden: { left: "20%" },
          visible: { left: "100%" },
        }}
        initial="hidden"
        animate={slideControls}
        transition={{ duration: 0.5, ease: "easeIn" }}
        style={{
          position: "absolute",
          top: 4,
          bottom: 4,
          left: "38%",
          right: "38%",
          background: "#3C3257",
          zIndex: 20,
        }}
      />
      </div>
    
    
    
    <div className="">
    <div className=""> 
        

    <ul className="feedback ">
    <div className="absolute w-screen flex justify-center flex-col min-h-[25rem] ">
        </div>
            {testimonials.map((test, i) => {
              return (
                <li key={i}>
                  <img src={test.src} alt="" className={styles[i]} />
                </li>
              );
            })}

          
          
            <FontAwesomeIcon icon={faChevronLeft} beatFade  style={{color: "#fff", fontSize: "3rem"}}   
            className="absolute left-16 cursor-pointer max536:left-6 max500:left-2 max406:h-[1.5rem]"
            onClick={leftClicked}
          /> 
           <FontAwesomeIcon icon={faChevronRight} beatFade  style={{color: "#fff", fontSize: "3rem"}}   
            className="absolute right-16 cursor-pointer max536:right-6 max500:right-2 max406:h-[1.5rem]"
            onClick={rightClicked}
          /> 
            
          



           
          </ul>
        </div>
        <div ref={ref} style={{ position: "relative", overflow: "hidden" }}>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 0.5, delay: 0.25 }}
      >
      
      <div className="flex relative z-2 object-cover  justify-center max1050:pl-8 max1050:pr-8 ">
      <h2 className="text-[1rem]  z-2 des text-white w-[45rem] max800:text-[1rem] max478:text-[0.9rem] text-center font-poppins"><span className="text-[1.4rem]">"</span>
      {testimonials[1].description} <span className="text-[1.4rem]">"</span>
      </h2>
      </div>
      <h1 className="mona h-[5rem] font-sansita   w-[100%]">- {testimonials[1].name}</h1>
      
      
      </motion.div>
      </div>
        

      {/*  <div className="flex  justify-center item-center pt-[1rem]">
        <img
        src={yellow_star}
        className="h-[2.6rem] mt-[0.5rem] max800:mt-[0.3rem] max800:h-[1.5rem] max406:h-[1rem]"
        alt=""
        />
        <img
        src={yellow_star}
        className="h-[2.6rem] mt-[0.5rem] max800:mt-[0.3rem] max800:h-[1.5rem] "
        alt=""
        />
        <img
            src={yellow_star}
            className="h-[2.6rem] mt-[0.5rem] max800:mt-[0.3rem] max800:h-[1.5rem] "
            alt=""
          />
          <img
            src={yellow_star}
            className="h-[2.6rem] mt-[0.5rem] max800:mt-[0.3rem] max800:h-[1.5rem] "
            alt=""
          />
          <img
          src={yellow_star}
            className="h-[2.6rem] mt-[0.5rem] max800:mt-[0.3rem] max800:h-[1.5rem]  "
            alt=""
          />
          </div>    */}
    </div>
    
    
   
      </div>
    </div>
    );
  };
  
export default Testimonial;
