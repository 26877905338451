
import React from "react";
import video from "../../Utils/ODISSI/video.mp4";
// import Head from "../../Utils/ODISSI/Head.png";
import "./Leo.css";
import {motion} from 'framer-motion';

let easeing = [0.6,-0.05,0.01,0.99];



const firstName = {
  initial:{
    y:-20,
  },
  animate:{
    y:0,
    transition:{
      delayChildren:0.4,
      staggerChildren:0.04,
      staggerDirection:-1
    }
  }
}




const Header = () => {

  return (

    //Header slogan
    <motion.div initial={{opacity:0,scale:0}} animate={{opacity:1, scale:1}} transition={{duration:0.3, ease:easeing}} className="bg-black bg-opacity-[50%] self-stretch overflow-hidden h-[36rem] flex flex-col items-start justify-start text-left text-[2rem] text-white ">
      <div  className="title absolute z-10 flex flex-col items-center h-[inherit] max800:px-[4rem] max375:px-[2rem] px-[10rem] pb-[5rem] justify-end w-screen">
          <motion.h5>
          <motion.span variants={firstName} initial="initial" animate="animate"  className="first Fussion m-0 font-custom1 relative text-[2.4rem] max800:text-[1.2rem]    max375:text-[1.2rem]  max375:leading-[1.6rem] max600:text-[2rem] max600:leading-[3rem] leading-[2.94rem] font-bold font-inherit ">
                                
          </motion.span>
          </motion.h5>
          </div>
          <motion.div className="z-10 ">
       {/*   <img src={Head} className="object-cover bg-[#000] mt-[1rem] h-[30rem] w-screen max-w-screen " alt="" /> */}
         <video
          autoPlay
          loop
          muted
          playsInline={true}
          controls={false}
          className="object-cover bg-[#0000] mt-[1rem] h-[30rem] w-screen max-w-screen "
          >
          <source src={video} type="video/mp4" />
  </video>  
          </motion.div>
          
          <motion.h5  className="m-0  w-full flex items-center justify-center  bg-black  h-[5rem] shrink-0 text-[1rem] font-inherit">
            <motion.p 
            initial={{
              x:-1040,
              opacity:0,
              transition:{
              duration:0.6, ease:easeing
              }
            }} 

            animate={{
              x:0,
              opacity:1,
              transition:{
              duration:0.6,
              delay:0.5,
              ease:easeing
              }
            }}  

            className="Fussion1 text-center  text-[0.7rem] max375:text-[1rem]">
            Ignite Your Dancing Flame 
            </motion.p>
          </motion.h5>

    </motion.div>
  );
};

export default Header;
