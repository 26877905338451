import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import NavBar from "../Components/NavBar";
import "./about_us.css";
import Footer from '../Components/Home/Footer'

const AboutUs = () => {
  const mainControls = useAnimation();
  const slideControls = useAnimation();

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      slideControls.start("visible");
      mainControls.start("visible");
    } else {
      slideControls.start("hidden");
      mainControls.start("hidden");
    }
  }, [isInView, mainControls, slideControls]);


  

  return (
    <div>
    <div className="flex flex-col items-center text-justify Background1 pb-[8rem] h-[100%] w-screen">
      <NavBar />
      <div className="text-[white] flex flex-col items-center  w-100 h-100 mt-[2rem] p-0  w-[90vw] max-w-[80rem]">
        <h1  className=" text-[4rem] text-center max450:[1.2rem] font-Dosis">About Us</h1>
        <h3 className="text-[2rem] max450:text-[1.8rem] font-poppins text-left">KNOW WHAT WE ARE</h3>
        
        <div ref={ref} style={{ position: "relative", overflow: "hidden" }}>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 0.5, delay: 0.25 }}
      >
        

        <p className="mt-8 h-100">
        ICONIC is a prominent dance and fitness studio nestled in the heart of Berhampur, Odisha. 
        Our belief in the transformative power of dance drives us. We see dance as a way to express 
        oneself, breaking boundaries and embracing diversity. Our studio is a welcoming space for people
        of all backgrounds and ages to discover the joy of movement, explore their inner artist, and 
        connect within a lively community.
        </p>
        
        <p className="mt-8 h-100">
        Founded in 2014 by the visionary Narthu Balaraju, a respected master instructor skilled in various 
        dance forms, Iconic has become a central figure in the local dance scene. Balaraju's expertise in 
        hip-hop guides our studio. What started as a passionate endeavor has grown into a nurturing sanctuary 
        where creativity thrives and dreams take shape.

        </p>
        
        <p className="mt-8 h-100">
        Our journey is marked by impressive achievements, including captivating performances on popular
        reality shows like Dance+, Dance Deewane, and DOD. The Iconic Crew consistently wows audiences
        with their exceptional talents.
        </p>
        
        <p className="mt-10 h-100">
        Whether you're a seasoned dancer or taking your first steps on the dance floor, our studio offers a supportive and 
        encouraging atmosphere. Our classes cater to a range of skill levels, ensuring that everyone can find their rhythm 
        and build their dance journey at their own pace. Come be a part of our dynamic community at Iconic, where we celebrate 
        the beauty of movement and the boundless possibilities of self-expression.
        </p>


      </motion.div>
      <motion.div
        variants={{
          hidden: { left: 0 },
          visible: { left: "100%" },
        }}
        initial="hidden"
        animate={slideControls}
        transition={{ duration: 0.5, ease: "easeIn" }}
        style={{
          position: "absolute",
          top: 4,
          bottom: 4,
          left: 0,
          right: 0,
          background: "#3C3257",
          zIndex: 20,
        }}
      />
    </div>
        
        
    
  </div> 
  </div>
  <Footer />
      </div>
  );
};

export default AboutUs;
