import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import MenuPng from "../Utils/images/NavBar/Menu.svg";
import CrossPng from "../Utils/images/NavBar/cross.png";
import Context from "../Context/Context";
import Logo from "../Utils/ODISSI/Logo/logo.jpg";
import { useLocation } from "react-router-dom";
// import { Link } from "react-scroll";
// import "./Nav.css"
import { motion } from "framer-motion";

let easeing = [0.6, -0.05, 0.01, 0.99];

const stagger = {
  animate: {
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.2,
      staggerDirection: 1,
    },
  },
};

// const fadeInUp = {
//   initial:{
//     y:-60,
//     opacity:0,
//     transition:{
//       duration:0.6, ease:easeing
//     }
//   },
//   animate:{
//     y:0,
//     opacity:1,
//     transition:{
//       duration:0.6,
//       delay:0.5,
//       ease:easeing
//     }
//   }
// };

// const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };

const firstName = {
  initial: {
    y: -20,
  },
  animate: {
    y: 0,
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.04,
      staggerDirection: -1,
    },
  },
};

// const lastName = {
//   initial:{
//     y:-20,
//   },
//   animate:{
//     y:0,
//     transition:{
//       delayChildren:0.4,
//       staggerChildren:0.04,
//       staggerDirection:1
//     }
//   }
// }

// const letter = {
//   initial:{
//     y:400,
//   },
//   animate:{
//     y:0,
//     transition:{duration:1, ...transition}
//   }
// };

// const btnGroup={
//   initial:{
//     y:-60,
//     opacity:0,
//     transition:{duration:0.6, ease:easeing}
//   },
//   animate:{
//     y:0,
//     opacity:1,
//     animation:{
//       duration:0.6,
//       ease:easeing
//     }
//   }
// };
// const star={
//   initial:{
//     y:60,
//     opacity:0,
//     transition:{duration:0.8, ease:easeing}
//   },
//   animate:{
//     y:0,
//     opacity:1,
//     animation:{
//       duration:0.6,
//       ease:easeing
//     }
//   }
// };

const header = {
  initial: {
    y: -60,
    opacity: 0,
    transition: { duration: 0.05, ease: easeing },
  },
  animate: {
    y: 0,
    opacity: 1,
    animation: {
      duration: 0.6,
      ease: easeing,
    },
  },
};

const NavBar = () => {
  const [isNavActive, setIsNavActive] = useState(false);
  const UserCtx = useContext(Context);

  const Navigate = useNavigate();
  const location = useLocation();

  return (
    <motion.div
      initial="initial"
      animate="animate"
      className={`   h-8  ${
        location.pathname === "/dashboard" ? "max536:h-[5rem]  max536:mt-0" : ""
      } `}
    >
      <motion.div
        variants={stagger}
        className="flex  z-20 fixed items-center justify-between text-white w-screen bg-black border-b border-[#3C3257]  h-[4.8rem] px-10 left-0 max536:bg-black"
      >
        <motion.a
          variants={header}
          href="/"
          className={`logo ${
            location.pathname === "/dashboard" ? "max536:hidden" : ""
          }`}
        >
          {/* <img
    className="relative rounded-301xl w-[3rem] h-[3rem] shrink-0 object-cover"
    alt=""
    src={logo}
    /> */}
          <motion.img
            src={Logo}
            variants={firstName}
            initial="initial"
            animate="animate"
            className="relative rounded-[0.41rem] w-[10rem] max600:w-[5rem] max600:h-[2rem] object-top h-[3rem] shrink-0 object-cover max600:object-contain "
          />
            
        
        </motion.a>
        {/*      Main Top Nav Bar           */}
        <motion.ul
          variants={stagger}
          className="flex gap-6  max800:hidden font-inter  "
        >
          <motion.li
            variants={header}
            className="flex items-center justify-center  hover:text-[#3C3257] "
          >
            <p
              className="cursor-pointer"
              onClick={() => {
                Navigate("/");
              }}
            >
              HOME
            </p>
          </motion.li>
          <motion.li
            variants={header}
            className="flex items-center justify-center cursor-pointer hover:text-[#3C3257]"
          >
            <p
              className="cursor-pointer"
              onClick={() => {
                Navigate("/aboutus");
              }}
            >
              ABOUT US
            </p>
          </motion.li>

          <motion.li
            variants={header}
            className="flex items-center justify-center  cursor-pointer hover:text-[#3C3257] "
          >
            <p
              className="cursor-pointer"
              onClick={() => {
                Navigate("/instructor");
              }}
            >
              INSTRUCTOR
            </p>
          </motion.li>
          <motion.a
            variants={header}
            className="max800:hidden flex items-center justify-center p-0 m-0"
          >
            {UserCtx.isAuth ? (
              <p
                onClick={() => {
                  Navigate("/dashboard");
                }}
                className="cursor-pointer overflow-hidden   max800:hidden w-[10rem] text-center mr-10  p-2 py-1 border-solid border-2 border-[#3C3257] rounded-md "
              >
                {UserCtx.userData.userName.split(" ")[0]}
              </p>
            ) : (
              <motion.button
                variants={header}
                className="max800:hidden  p-2 py-1 bg-[#3C3257] w-[6.5rem] h-[2.63rem] rounded-md text-white font-russo"
                onClick={() => {
                  Navigate("/login");
                }}
              >
                Login
              </motion.button>
            )}
          </motion.a>
        </motion.ul>
        {/*      Second NavBar           */}

        <motion.div
          variants={stagger}
          className={`relative min800:hidden max800:block w-[auto] ${
            location.pathname === "/dashboard" ? "max536:hidden" : ""
          }`}
          onClick={() => {
            setIsNavActive(!isNavActive);
          }}
        >
          <AiOutlineMenu color={"white"} />
          {isNavActive && (
            <motion.ul
              variants={stagger}
              className="bg-black shadow-lg w-[10rem] absolute top-[1.6rem] right-0 z-40"
            >
              <motion.li
                variants={header}
                className="flex items-center justify-center h-10   "
              >
                <p
                  className="cursor-pointer hover:bg-[#3C3257] "
                  onClick={() => {
                    Navigate("/");
                  }}
                >
                  Home
                </p>
              </motion.li>
              <motion.li
                variants={header}
                className="flex items-center justify-center h-10  hover:bg-[#3C3257]"
              >
                <p
                  className="cursor-pointer"
                  onClick={() => {
                    Navigate("/aboutus");
                  }}
                >
                  About Us
                </p>
              </motion.li>

              <motion.li
                variants={header}
                className="flex items-center justify-center h-10  hover:bg-[#3C3257]"
              >
                <p
                  className="cursor-pointer"
                  onClick={() => {
                    Navigate("/instructor");
                  }}
                >
                  Instructor
                </p>
              </motion.li>
              {UserCtx.isAuth ? (
                <motion.li
                  variants={header}
                  className="flex items-center overflow-hidden  justify-center h-10  hover:bg-[#3C3257] "
                  onClick={() => {
                    Navigate("/dashboard");
                  }}
                >
                  {UserCtx.userData.userName.split(" ")[0]}
                </motion.li>
              ) : (
                <motion.li
                  variants={header}
                  className="flex items-center justify-center h-10  hover:bg-[#3C3257] "
                  onClick={() => {
                    Navigate("/login");
                  }}
                >
                  Login
                </motion.li>
              )}
            </motion.ul>
          )}
        </motion.div>

        {/*       Mobile Navbar        */}
        <motion.div
          variants={stagger}
          className={`min536:hidden max536:fixed top-0 left-0 z-40 bg-black`}
        >
          {/* <div
        className={`min536:hidden max536:fixed top-0 left-0 z-40 bg-black  ${
          location.pathname !== "/dashboard" ? "max536:hidden" : ""
        }`}
      > */}
          {isNavActive ? (
            <img
              src={CrossPng}
              alt=""
              className={` fixed top-10 right-6 z-60 cursor-pointer h-8 bg-[#3C3257]`}
              onClick={() => {
                setIsNavActive(!isNavActive);
              }}
            />
          ) : (
            <img
              src={MenuPng}
              alt=""
              className={`${
                location.pathname !== "/dashboard"
                  ? "max536:hidden max536:bg-white"
                  : ""
              } fixed top-4 right-6 z-60 cursor-pointer h-8 bg-transparent   `}
              onClick={() => {
                setIsNavActive(!isNavActive);
              }}
            />
          )}
          {/* <div
          className={`min536:hidden max536:fixed top-0 left-0 ${
            isNavActive ? "h-screen w-screen " : ""
          }`}
        ></div> */}
          {isNavActive && (
            <motion.div
              variants={stagger}
              className="bg-[#3C3257] z-40  h-screen w-screen text-white RussoOne text-[1.8rem]"
            >
              <motion.ul
                variants={stagger}
                className="pt-24 flex flex-col items-start px-24 gap-8"
              >
                <motion.li
                  variants={header}
                  className="flex items-center justify-center cursor-pointer text-white"
                >
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      Navigate("/");
                    }}
                  >
                    Home
                  </p>
                </motion.li>
                <motion.li
                  variants={header}
                  className="flex items-center justify-center cursor-pointer text-white"
                >
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      Navigate("/aboutus");
                    }}
                  >
                    About Us
                  </p>
                </motion.li>

                <motion.li
                  variants={header}
                  className="flex items-center justify-center cursor-pointer text-white"
                >
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      Navigate("/instructor");
                    }}
                  >
                    Instructor
                  </p>
                </motion.li>
                {UserCtx.isAuth ? (
                  <motion.li
                    variants={header}
                    className="flex items-center overflow-hidden  justify-center h-10 cursor-pointer border-b border-white "
                    onClick={() => {
                      Navigate("/dashboard");
                    }}
                  >
                    {UserCtx.userData.userName.split(" ")[0]}
                  </motion.li>
                ) : (
                  <motion.li
                    variants={header}
                    className="flex items-center justify-center h-10 cursor-pointer border-b border-white "
                    onClick={() => {
                      Navigate("/login");
                    }}
                  >
                    Login
                  </motion.li>
                )}
              </motion.ul>
            </motion.div>
          )}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default NavBar;
