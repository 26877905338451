//img
import Logo from "../../Utils/ODISSI/logo1.jpg";
import facebook from "../../Utils/Assests/FB.svg";
import instagram from "../../Utils/Assests/INSTA.svg";
import youtube from "../../Utils/Assests/YOU.svg";

import {  useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import "./Footer.css";

const Footer = (props) => {
  const Navigate = useNavigate();
  // const [content, setContent] = useState(props.initialContent);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.initialContent]);

  // const handleContentChange = (event) => {
  //   setContent(event.target.value);
  // };

  return (
    <div>
      <div className="bg-black">
        <div className="flex flex-wrap justify-between p-12 gap-6 max1008:justify-center  max1330:flex-col">
          <div className="mb-5">
            <a href="/" className="transition duration-200 flex max1330:justify-center">
              <img src={Logo}  className="w-[15rem] max450:w-[10rem] rounded-lg" alt="" />
            </a>{" "}
            {/*    logo   */}
          </div>

          <ul className=" flex gap-32 max950:gap-16 text-white flex-wrap max1330:justify-center text-left ">
            {" "}
           
            <li className="RussoOne flex flex-col gap-4 items-center text-center">
              <h2>Affiliated Gyms</h2>
              <div className="w-[100%] h-[0.2rem] text-white bar"></div>
              <a
                className="cursor-pointer"
                href="https://happyprancer.com/"
                target={"_blank"}
                rel="noreferrer"
              >
                HappyPrancer
              </a>
              <a
                className="cursor-pointer"
                href="https://bworkzlive.com/"
                target={"_blank"}
                rel="noreferrer"
              >
                BWORKZ
              </a>
              <a
                className="cursor-pointer"
                href="http://awsaiapp.com.s3-website-us-east-1.amazonaws.com/"
                target={"_blank"}
                rel="noreferrer"
              >
                AWSAIAPP
              </a>
            </li>
          </ul>
        </div>

        <div className="py-[0.4rem] px-8 h-16 bg-[#3C3257]">
          {" "}
          {/* Social Contacts   */}
          <div className="flex bg-white justify-between items-center w-[15%] min-w-[10rem] max-w-[12rem] rounded-2xl h-12 p-4">
            <a
              href="https://instagram.com/iconidancestudio?igshid=NGExMmI2YTkyZg=="
              target={"_blank"}
                rel="noreferrer"
            >
              <img
                src={instagram}
                alt=""
                className="hover:mr-2 hover:w-10 hover:h-8 w-5 h-5"
              />
            </a>
           <a
              href="https://m.facebook.com/ayesha.patro.7"
              target={"_blank"}
                rel="noreferrer"
            >
              <img
                src={facebook}
                alt=""
                className="hover:mr-2 hover:w-10 hover:h-8 w-5 h-5"
              />
  </a> 
            <a href="https://youtube.com/@ayeshapatro1723" target={"_blank"}
                rel="noreferrer">
              <img
                src={youtube}
                alt=""
                className="hover:mr-1 hover:w-10 hover:h-8 w-5 h-5"
              />
            </a>
          </div>
        </div>

        <div className="RussoOne p-4 flex justify-center text-white gap-2">
          <h5>
            <p
              className="cursor-pointer"
              onClick={() => {
                Navigate("/query");
              }}
            >
              Contact Us
            </p>
          </h5>
          <div className="bg-[#3C3257] w-1 border-white rounded-md"></div>
          <h5>&copy; All Rights are Reserved By  ICONIC DANCE STUDIO</h5>
        </div>
      </div>
    </div>
  );
};

export default Footer;
