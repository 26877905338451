import React from "react";

// import mid_img from "../../Utils/ODISSI/main.png";
import "./Services.css";



const Header2 = () => {
  return (
    <div 
      className="New	flex justify-between max600:h-[95rem] max950:h-[68rem]  h-[60rem] text-white blurimg w-[auto] relative pt-[3.5rem] pb-20 pr-5 pl-5 max600:flex-col max600:mx-0 max600:items-start
     max600:m-0 max600:w-[90vw] overflow-hidden max800:gap-[2rem]"
    >
      <div className="p-10 flex flex-col max600:items-center  bg-transparent border-y-[0.4rem] rounded-tl-lg rounded-bl-lg border-l-[0.4rem] border-[#3C3257] w-[38vw] h-[50rem] max600:h-auto max600:border-0 max600:w-[100%] max950:h-[60rem] max950:gap-[11rem] gap-[7rem] max600:gap-12">
        <div className="w-[20rem] max800:w-[14rem] max600:w-[100%]  ">
          <h1 className="text-[2rem] max800:text-[1.5rem] font-russo max600:text-[1.6rem] capital">
          Bollywood Fusion
          </h1>
          <ul className="max800:text-[0.8rem] list-disc font-inter">
          <li>Immerse yourself in the magic of Bollywood with a fusion of traditional and contemporary moves.
          </li> 
          <li>Celebrate the essence of Indian cinema through expressive choreographies.</li> 
          <li>Immerse yourself in a world of vibrant colors, storytelling, and rhythmic beats.</li> 
          <li>Join our classes to groove to iconic Bollywood tunes and showcase your flair for drama.
          </li> 
          </ul>
        </div>
<div className="w-[20rem] max800:w-[14rem] max600:w-[100%]">
          <h1 className="text-[2rem] max800:text-[1.5rem] font-russo max600:text-[1.6rem] capital">
          House Grooves
          </h1>
          <ul className="max800:text-[0.8rem] list-disc font-inter">
            <li>Experience the rhythmic world of house dance with energetic choreographies</li>
            <li>Learn footwork, jacking, and lofting while embracing the energetic house music vibe.</li>
            <li>Explore the origins of house dance and its cultural significance.
            </li>
            <li>Join our classes to master the dynamic footwork and free-flowing movements of house dance.
            </li>
          </ul>
        </div>
        
      </div>

      <div className="Over p-10 flex flex-col max600:items-center max600:pt-0 max950:gap-[5rem] gap-[4rem] items-end bg-transparent border-y-[0.4rem] rounded-tr-lg rounded-br-lg border-r-[0.4rem] border-[#3C3257] w-[38vw] h-[50rem] max950:h-[60rem]  max600:h-auto max600:border-0 max600:w-[100%]  max800:gap-[7rem] ">
        <div className="w-[20rem]  max800:w-[14rem] max600:w-[100%] ">
          <h1 className="text-[2rem] max800:text-[1.5rem] max600:text-[1.6rem] font-russo  max950:pl-[3rem] max600:pl-0 capital">
          Breaking Move
          </h1>
          <ul className="max800:text-[0.8rem] list-disc max950:pl-[2rem] max600:pl-0 font-inter">
            <li>
            Dive into the thrilling realm of breaking with dynamic choreographies that defy gravity
            </li>
            <li>
            Master power moves, freezes, and intricate footwork, showcasing your strength and agility.
            </li>
            <li>
            Discover the history and philosophy behind breaking, a vibrant part of hip-hop culture.
            </li>
            <li>
            Join us to express yourself through explosive moves and become part of the breaking community.

            </li>
            
          </ul>
        </div>  <div className="w-[20rem] max800:w-[14rem] max600:w-[100%]">
            <h1 className="text-[2rem] max800:text-[1.5rem] max600:text-[1.6rem] font-russo  max950:pl-[3rem] max600:pl-0 capital">
            Special Choreographies
            </h1>
            <ul className="max800:text-[0.8rem] list-disc max950:pl-[3rem] max600:pl-0 font-inter">
              <li> Customized Choreography for Special Events</li>
              <li>Elevate your special occasions with bespoke choreographies that captivate.</li>
              <li> From graceful wedding waltzes to energetic annual function performances, we have you covered.</li>
              <li> Tailor-made dance routines to complement your celebrations and preferences
              </li>
              <li> Let our expert choreographers create magical memories through dance
              </li>
            
            </ul>
          </div>
        
      </div>

     {/* <img
        src={mid_img}
        className="xs:block hidden absolute left-[53.9%] -translate-x-[68%]  w-[55vw] max1078:-left-[50.9%]  borderbox-hidden bottom-[-39px]  size1"
        alt=""
  /> */}
    </div>
  );
};

export default Header2;
