import React, { useState,useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

import arrow from "../../Utils/Assests/arrow.svg";
import "./FAQ.css"

const FAQ = () => {
  const [fav2Visible1, setfav2Visible1] = useState(false);
  const [fav2Visible2, setfav2Visible2] = useState(false);
  const [fav2Visible3, setfav2Visible3] = useState(false); 
  const [fav2Visible4, setfav2Visible4] = useState(false); 
  // const [fav2Visible4, setfav2Visible4] = useState(false); 

  const fav2Handler1 = () => {
    setfav2Visible1(!fav2Visible1);
  };
  const fav2Handler2 = () => {
    setfav2Visible2(!fav2Visible2);
  };
  const fav2Handler3 = () => {
    setfav2Visible3(!fav2Visible3);
  };
  const fav2Handler4 = () => {
    setfav2Visible4(!fav2Visible4);
  };
  // const fav2Handler4 = () => {
  //   setfav2Visible4(!fav2Visible4);
  // };

  const mainControls = useAnimation();
  const slideControls = useAnimation();

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      slideControls.start("visible");
      mainControls.start("visible");
    } else {
      slideControls.start("hidden");
      mainControls.start("hidden");
    }
  }, [isInView, mainControls, slideControls]);

  return (
    <div className="justify-center min-h-[70vh] flex flex-col items-center gap-8">
    <div ref={ref} style={{ position: "relative", overflow: "hidden" }}>
    
    <motion.div
      variants={{
        hidden: { opacity: 0, y: -75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: 0.5 }}
      >
      <h1 className="text-[4rem] max800:text-[3.2rem] mt-[2rem] font-russo">FAQs</h1>
      </motion.div>
      <motion.div
        variants={{
          hidden: { left: 0 },
          visible: { left: "100%" },
        }}
        initial="hidden"
        animate={slideControls}
        transition={{ duration: 0.5, ease: "easeIn" }}
        style={{
          position: "absolute",
          top: 4,
          bottom: 4,
          left: 0,
          right: 0,
          background: "#3C3257",
          zIndex: 5,
        }}
      />
      </div>

      <div ref={ref} style={{ position: "relative", overflow: "hidden" }}>
      <ul className=" flex  justify-center items-center flex-col ">
           <motion.div
            variants={{
              hidden: { opacity: 0, x: -270 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.5, delay: 0.5 }}
            > 
      <li className={`flex justify-center mb-1`}>
        <div className="flex  justify-center items-center  mb-4 w-20 bg-[#3C3257] max500:w-[3rem]">
        <h2 className="font-russo text-white">01</h2>
      </div>
      <div className=" FAQ-Back bg-[#D9D9D9] p-4 mb-4 w-[60vw] text-[1.1rem] transition-all duration-200 ease-in">
      <p className="text-white max600:text-[1.1rem] txt5 flex justify-between">
      How far is ICONIC dance studio from my home?
              {fav2Visible1 ? (                                                   //arrow animation
                <img
                  src={arrow}
                  className="transition ease-out duration-1500"
                  alt=""
                  onClick={fav2Handler1}
                />
              ) : (
                <img
                  id="close-arrow"
                  src={arrow}
                  className="transition ease-out duration-1500 h-[100%]"
                  alt=""
                  onClick={fav2Handler1}
                />
              )}
            </p>
            {fav2Visible1 && (
              <p className="text-white  text-[1rem] transition-text  duration-[5000ms] ease-in mt-[0.2rem]">
              ICONIC dance studio is conveniently located in the heart of Berhampur, you can find us  (<a href="https://maps.app.goo.gl/9Dy52oTgeEQvekAX8" className="font-bold decoration-current" rel="noreferrer" target="_blank" >HERE.</a> )

              </p>
            )}
          </div>
        </li>
        </motion.div>

        
              <motion.div
              variants={{
                hidden: { opacity: 0, x: -85 },
                visible: { opacity: 1, x: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.8, delay: 0.8 }}
              >
          <li className={`flex justify-center`}>
          <div className="flex  justify-center items-center  mb-4 w-20 bg-[#3C3257] max500:w-[3rem]">
            <h2 className="font-russo text-white">02</h2>
          </div>
          <div className=" FAQ-Back p-4 mb-4 w-[60vw] text-[1.1rem] transition-all duration-200 ease-in">
            <p className="text-white max600:text-[1.1rem] txt5 flex justify-between">
            What are the pricing details in short?
              {fav2Visible2 ? (
                <img
                  src={arrow}
                  className="transition ease-out duration-1500"                     //arrow animation
                  alt=""
                  onClick={fav2Handler2}
                />
              ) : (
                <img
                  id="close-arrow"
                  src={arrow}
                  className="transition ease-out duration-1500"
                  alt=""
                  onClick={fav2Handler2}
                />
              )}
            </p>
            {fav2Visible2 && (
              <p className="text-white text-[1rem] mt-[0.5rem]">
              ICONIC offers competitive pricing with a one-time registration fee and affordable monthly fees for both kids and adults. Reach out to us for detailed pricing information.
              </p>
            )}
          </div>
          </li>
          </motion.div>

        
        <motion.div
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 1, delay: 1 }}
            >
          <li className={`flex justify-center`}>
          <div className="flex  justify-center items-center  mb-4 w-20 bg-[#3C3257]  max500:w-[3rem]">
            <h2 className="font-russo text-white">03</h2>
          </div>
          <div className=" FAQ-Back p-4 mb-4 w-[60vw] text-[1.1rem] transition-all duration-200 ease-in">
            <p className="text-white max600:text-[1.1rem] txt5 flex justify-between">
            Can dancing at ICONIC help reduce calories?
              {fav2Visible3 ? (
                <img
                  src={arrow}
                  className="transition ease-out duration-1500"                             //arrow animation
                  alt=""
                  onClick={fav2Handler3}
                />
              ) : (
                <img
                  id="close-arrow"
                  src={arrow}
                  className="transition ease-out duration-1500"
                  alt=""
                  onClick={fav2Handler3}
                />
              )}
            </p>
            {fav2Visible3 && (
              <p className="text-white text-[1rem] mt-[0.5rem]">
              Yes, dancing is a fantastic way to burn calories while enjoying yourself. Our energetic dance sessions contribute to a healthier lifestyle.

              </p>
            )}
          </div>
          </li>
          </motion.div>
        <motion.div
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 1, delay: 1 }}
            >
          <li className={`flex justify-center`}>
          <div className="flex  justify-center items-center  mb-4 w-20 bg-[#3C3257]  max500:w-[3rem]">
            <h2 className="font-russo text-white">03</h2>
          </div>
          <div className=" FAQ-Back p-4 mb-4 w-[60vw] text-[1.1rem] transition-all duration-200 ease-in">
            <p className="text-white max600:text-[1.1rem] txt5 flex justify-between">
            What classes does ICONIC offer?
              {fav2Visible4 ? (
                <img
                  src={arrow}
                  className="transition ease-out duration-1500"                             //arrow animation
                  alt=""
                  onClick={fav2Handler4}
                />
              ) : (
                <img
                  id="close-arrow"
                  src={arrow}
                  className="transition ease-out duration-1500"
                  alt=""
                  onClick={fav2Handler4}
                />
              )}
            </p>
            {fav2Visible4 && (
              <p className="text-white text-[1rem] mt-[0.5rem]">
              ICONIC offers a diverse range of dance styles including Bollywood, hip-hop, house, breaking, and more. Explore various genres and find your groove with us.


              </p>
            )}
          </div>
          </li>
          </motion.div>
       
      </ul>
      </div>
    </div>
  );
};

export default FAQ;
