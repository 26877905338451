// import { API } from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LeftBanner from "../Components/DashBoard/LeftBanner";
import PreviousSessions from "../Components/DashBoard/PreviousSessions";
import ProfileUpdate from "../Components/DashBoard/ProfileUpdate";
import UpcomingSessions from "../Components/DashBoard/UpcomingSessions";
import UsersList from "../Components/DashBoard/UsersList";
import NavBar from "../Components/NavBar";
import Context from "../Context/Context";

const DashBoard = () => {
  const [click, setClick] = useState(0);
  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [status, setStatus] = useState("Active");
  // const [balance, setBalance] = useState("");
  const Ctx = useContext(Context);
  // const UtilCtx = useContext(Context).util;
  const [userCheck, setUserCheck] = useState(0);
  // const [displayProfile, setDisplayProfile] = useState(false);

  const Navigate = useNavigate();

  const displayAfterClick = () => {
    switch (click) {
      case 0:
        return <UpcomingSessions />;

      case 1:
        return <PreviousSessions />;

      // case 2:
      //   return <Choreography />;

      case 3:
        return <ProfileUpdate />;

      case 4:
        return <UsersList userCheck={userCheck} setUserCheck={setUserCheck} />;

      default:
        return <div></div>;
    }
  };
  useEffect(() => {
    if (Ctx.isUserDataLoaded) {
      if (Ctx.userData.userType !== "admin") {
        Navigate("/");
      }
    }
  }, [Ctx, Navigate]);
  // const toggleProfile = () => {
  //   setDisplayProfile(!displayProfile);
  // };

  // const onCreateUser = async (e) => {
  //   e.preventDefault();

  //   if (!(name && email && phoneNumber && status && balance )) {
  //     alert("Fill all Fields");
  //     return;
  //   }
  //   if (!name) {
  //     alert("Fill Name");
  //     return;
  //   } else if (!email) {
  //     alert("Fill email");
  //     return;
  //   } else if (!phoneNumber) {
  //     alert("Fill Phone Number");
  //     return;
  //   } else if (!status) {
  //     alert("Fill Status");
  //     return;
  //   } else if (!balance) {
  //     alert("Fill Balance");
  //     return;
  //   } 

  //   UtilCtx.setLoader(true);

  //   try {
  //     console.log("started");

  //     await API.post("user", "/admin/create-user/iconic", {
  //       body: {
  //         emailId: email,
  //         userName: name,
  //         phoneNumber: phoneNumber,
  //         status: status,
  //         balance: balance,
  //       },
  //     });
  //     Ctx.setUserList();

  //     alert("User Added");

  //     setName("");
  //     setEmail("");
  //     setPhoneNumber("");
  //     setStatus("");
  //     setBalance("");

  //     UtilCtx.setLoader(false);
  //   } catch (e) {
  //     console.log(e);
  //     UtilCtx.setLoader(false);
  //   }
  // };

  return (
    <div className="flex flex-col items-center w-screen h-auto bg-gradient-to-b min536:from-[#e8e8e9] max536:from-[#555657] min300:via-[#d46893] max536:via-[#3C325742] max536:to-[#3C3257] min300:to-[#3C3257]">
      <NavBar />
      <div className="w-[calc(100vw-1rem)] ml-4 rounded-3xl  flex max1050:w-screen max1050:ml-0 max536:rounded-none max536:mt-10 items-center">
        <LeftBanner
          displayAfterClick={(data) => {
            setClick(data);
          }}
        />

        <div className="flex flex-col pt-8 max536:pt-0 justify-start max800:justify-center w-[calc(100vw-16rem)] max1050:w-screen  max1050:items-center">
          
          <div className="min-h-[calc(100vh-4rem)] max1050:flex ">
          {displayAfterClick()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
