import React, { useEffect, useRef  } from "react";
import { motion, useAnimation,useInView } from "framer-motion";
import { useNavigate } from "react-router";
import "../comp/Subscription.css";

export default function Subscription() {
  const mainControls = useAnimation();
  const slideControls = useAnimation();

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });


  useEffect(() => {
    if (isInView) {
      slideControls.start("visible");
      mainControls.start("visible");
    } else {
      slideControls.start("hidden");
      mainControls.start("hidden");
    }
  }, [isInView, mainControls, slideControls]);

  const Navigate = useNavigate();

  const subscriptionDetails = [
    {
      title: "Benefits of Registration",
      pricing:"Pricing Details",
      price: ["₹1000", "₹1000 per month", "₹1200 per month"],
      description: ["One-time Registration Fee", "Monthly Fee for Kids", "Monthly Fee for Adults"]
    },
  ];
  const Benefits = [
    {
      pricing:"Pricing Details",
      price: ["₹1000", "₹1000 per month", "₹1200 per month"],
      description: ["Exclusive Access: Unlock full access to our diverse range of dance classes and workshops.", "Priority Updates: Stay informed about upcoming events, performances, and special sessions.", "Personalized Experience: Receive tailored recommendations and choreography based on your skill level","Healthy Lifestyle: Embrace a healthier lifestyle through regular dance practice"," Skill Progression: Track your dance journey and witness your skills improve over time."]
    },
  ];

  return (
    <>
      <section className="Back text-[1.5rem] py-[5rem] flex flex-col items-center h-[80rem] max1414:h-[auto] justify-center gap-[5rem]">
        <div className="text-center w-[100%] text-[white] text-[2rem] max500:text-[2rem] mt-20 font-bebas-neue">
          <div
            ref={ref}
            style={{ position: "relative", overflow: "hidden" }}
            className="w-[100%]"
          >
            <motion.div
              variants={{
                hidden: { opacity: 0, y: -75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              className="w-full"
              transition={{ duration: 0.5, delay: 0.25 }}
            >
              <h1 className="max600:text-[1.2rem] max600:px-[2rem] px-[3rem] text-[2rem] font-Dosis max600:font-light font-bold  text-center">
                Unlock a world of dance, expression, and connection by registering with ICONIC dance studio. <br /> Here are the benefits you'll receive as part of your registration
              </h1>
            </motion.div>
            <motion.div
              variants={{
                hidden: { left: "20%" },
                visible: { left: "100%" },
              }}
              initial="hidden"
              animate={slideControls}
              transition={{ duration: 0.5, ease: "easeIn" }}
              style={{
                position: "absolute",
                top: 4,
                bottom: 4,
                left: "38%",
                right: "38%",
                background: "#3C3257",
                zIndex: 20,
              }}
            />
          </div>
          <div
            ref={ref}
            style={{ position: "relative", overflow: "hidden" }}
          >
            <motion.div
              variants={{
                hidden: { opacity: 0, y: -75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.5, delay: 0.5 }}
            >
              <h3 className="text-[1.5rem] max500:text-[1rem] font-Dosis">

              </h3>
            </motion.div>
            <motion.div
              variants={{
                hidden: { left: "20%" },
                visible: { left: "100%" },
              }}
              initial="hidden"
              animate={slideControls}
              transition={{ duration: 0.8, ease: "easeIn" }}
              style={{
                position: "absolute",
                top: 4,
                bottom: 4,
                left: "38%",
                right: "38%",
                background: "#3C3257",
                zIndex: 20,
              }}
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-center items-center w-full max-w-[80rem] gap-28 max800:flex-col">
          <div className="flex flex-wrap w-full gap-[10rem] justify-center items-center text-center">
            {subscriptionDetails.map((subscription, index) => (
              <div
                key={index}
                style={{ position: "relative", overflow: "hidden" }}
              >
                <motion.div
                  variants={{
                    hidden: { opacity: 0, x: -175 },
                    visible: { opacity: 1, x: 0 },
                  }}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ duration: 0.5, delay: 0.5 }}
                >
                  <div className="bg-white w-[38rem] text-center h-[48rem] rounded-[2rem] flex flex-col justify-center items-center gap-4 shadowSubscribe max500:w-[90vw] max450:gap-[1.2rem] max450:text-[1rem] max450:p-12 border-[0.1rem]">
                    <p className=" max600:text-[1.8rem] font-400  font-gidugu text-center bg-gradient-radial flex items-center justify-center leading-[1.5rem] h-[107px] w-[303px] max500:text-[1.9rem] text-[2rem] font-Dosis mb-[1.2rem]">
                      {subscription.title}
                    </p>
                    <div className="flex max600:text-[1.2rem] w-[27rem] flex-col text-left gap-5  font-Dosis">
                    <p className="justify-center items-center flex flex-col">{subscription.title}</p>  
                    <ul className="list-disc text-[1rem] ">
                        {Benefits.map((subscription, index) => (
                          <li key={index} className="font-poppins justify-center items-center flex flex-col">
                            <ul className="list-disc max600:text-[0.8rem] max600:w-[50%]">
                              {subscription.description.map((desc, descIndex) => (
                                <li key={descIndex}>
                                  {desc} 
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                      <p className="justify-center items-center flex flex-col">{subscription.pricing}</p>  
                      <ul className="list-disc text-[1rem] ">
                        {subscriptionDetails.map((subscription, index) => (
                          <li key={index} className="font-poppins justify-center items-center flex flex-col">
                          <ul className="list-disc max600:text-[0.8rem] max600:w-[50%]">
                              {subscription.description.map((desc, descIndex) => (
                                <li key={descIndex}>
                                  {desc} - <span className="font-bold">{subscription.price[descIndex]}</span>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>

                    </div>
                    <button
                      onClick={() => {
                        Navigate("/query");
                      }}
                      className="w-[15rem] leading-0 textbox px-12 py-2 font-russo rounded-[11px] mt-[2rem] hover:text-[#ffff] hover:bg-[#3C3257] border-[#3C3257] border-[1px] text-[#3C3257] h-[3rem] flex justify-center items-center max450:w-[40vw]"
                    >
                      Continue
                    </button>
                  </div>
                </motion.div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
