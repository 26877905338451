import React, { useState } from "react";
import { useContext } from "react";
import Context from "../../Context/Context";
import { AiFillEdit } from "react-icons/ai";
import UserNamePng from "../../Utils/images/UsersList/userName.png";
import DetailsPng from "../../Utils/images/UsersList/details.png";
import DuePng from "../../Utils/images/UsersList/due.png";
import AttendancePng from "../../Utils/images/UsersList/attendance.png";
import { API } from "aws-amplify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";

const UsersList = ({ userCheck, setUserCheck }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState("Active");
  const [balance, setBalance] = useState("");
  const [cognitoId, setCognitoId] = useState("");
  const [isUserAdd, setIsUserAdd] = useState(false);
  const Ctx = useContext(Context);
  const UtilCtx = useContext(Context).util;

  const itemsPerPage = 10; // Set the desired number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(Ctx.userList.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const onUpdateUser = async (e) => {
    e.preventDefault();

    if (!(name && email && phoneNumber && status && balance)) {
      alert("Fill all Fields");
      return;
    }
    if (!name) {
      alert("Fill Name");
      return;
    } else if (!email) {
      alert("Fill email");
      return;
    } else if (!phoneNumber) {
      alert("Fill Phone Number");
      return;
    } else if (!status) {
      alert("Fill Status");
      return;
    } else if (!balance) {
      alert("Fill Balance");
      return;
    }

    UtilCtx.setLoader(true);

    try {
      console.log("started");

      await API.put("user", "/admin/update-user/iconic", {
        body: {
          cognitoId: cognitoId,
          emailId: email,
          userName: name,
          phoneNumber: phoneNumber,
          status: status,
          balance: balance,
        },
      });

      alert("User Updated");

      setName("");
      setEmail("");
      setPhoneNumber("");
      setStatus("");
      setBalance("");

      Ctx.onreload();

      UtilCtx.setLoader(false);
    } catch (e) {
      console.log(e);
      UtilCtx.setLoader(false);
    }
  };

  const Navigate = useNavigate();

  useEffect(() => {
    if (Ctx.isUserDataLoaded) {
      if (Ctx.userData.userType !== "admin") {
        Navigate("/");
      }
    }
  }, [Ctx, Navigate]);
  // const toggleProfile = () => {
  //   setDisplayProfile(!displayProfile);
  // };

  const onCreateUser = async (e) => {
    e.preventDefault();

    console.log(name, email, phoneNumber, status, balance);
  console.log(name && email && phoneNumber && status && balance);

  if (!(name && email && phoneNumber && status && balance)) {
    alert("Fill all Fields");
    return;
  }
  if (!name) {
    alert("Fill Name");
    return;
  } else if (!email) {
    alert("Fill email");
    return;
  } else if (!phoneNumber) {
    alert("Fill Phone Number");
    return;
  } else if (!status) {
    alert("Fill Status");
    return;
  } else if (!balance) {
    alert("Fill Balance");
    return;
  }

  UtilCtx.setLoader(true);

  try {
    console.log("started");

    await API.post("user", "/admin/create-user/iconic", {
      body: {
        emailId: email,
        userName: name,
        phoneNumber: phoneNumber,
        status: status,
        balance: balance,
      },
    });

    // Update the user list directly using the newly added user data
    const newUser = {
      emailId: email,
      userName: name,
      phoneNumber: phoneNumber,
      status: status,
      balance: balance,
    };

    // Assuming Ctx.userList is an array of users
    Ctx.setUserList((prevUserList) => [...prevUserList, newUser]);

    alert("User Added");

    setName("");
    setEmail("");
    setPhoneNumber("");
    setStatus("");
    setBalance("");
    window.scrollTo(0, document.body.scrollHeight);

    UtilCtx.setLoader(false);
  } catch (e) {
    console.log(e);
    UtilCtx.setLoader(false);
  }
};

  const sendReminder = async (cognitoId) => {
    UtilCtx.setLoader(true);

    const pa = "9853492252@ybl";
    const pn = "iconic";
    const am = 10;

    try {
      const res = await API.post("user", `/user/send-email/iconic`, {
        body: {
          pa,
          pn,
          am,
          cognitoId,
        },
      });

      console.log(res);

      alert(res.message);
      UtilCtx.setLoader(false);
    } catch (e) {
      console.log(e);
      UtilCtx.setLoader(false);
    }
  };

  return (
    <div>
      <div className="flex flex-col pt-8 max536:pt-0 justify-start max800:justify-center w-[calc(100vw-16rem)]  max1050:w-full  max1050:items-center">
        <div className="flex flex-col  w-full items-center  justify-center">
          <div className="flex w-full items-center justify-end max800:justify-center px-[2.5rem]">
            <button
              className="bg-[#800080] w-[8rem] rounded-[0.2rem] text-white mt-8 self-end my-[2rem]"
              onClick={() => {
                setUserCheck(1);
                setIsUserAdd(true);
              }}
            >
              Add New User
            </button>
          </div>
          {isUserAdd && userCheck === 1 && (
            <form className="flex flex-wrap gap-6  items-center justify-center max1250:w-[90%] max900:w-[auto] Sansita">
              <input
                required
                placeholder="Name"
                className="bg-[#ffffff79] text-[#0008] RussoOne px-4 py-1 rounded-lg w-[13rem]"
                type={"text"}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <input
                required
                placeholder="Email Address"
                className="bg-[#ffffff79] text-[#0008] RussoOne px-4 py-1 rounded-lg w-[13rem]"
                type={"email"}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />

              <input
                required
                className="bg-[#ffffff79] text-[#0008] RussoOne px-4 py-1 rounded-lg w-[13rem]"
                placeholder="Phone Number"
                type={"number"}
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
              />
              <select
                className="bg-[#ffffff79] text-[#0008] RussoOne px-4 py-1 rounded-lg w-[13rem]"
                onChange={(e) => {
                  setStatus(e.target.value);
                  console.log(e.target.value);
                }}
                value={status}
              >
                <option value={"Active"}>Active</option>
                <option value={"InActive"}>InActive</option>
              </select>
              <input
                required
                className="bg-[#ffffff79] text-[#0008] RussoOne px-4 py-1 rounded-lg w-[13rem]"
                placeholder="Balance"
                type={"number"}
                value={balance}
                onChange={(e) => {
                  setBalance(e.target.value);
                }}
              />
              <div className="flex  gap-3 w-full justify-center items-center">
                <button
                  className="RussoOne bg-[#800080] text-white w-[5rem] rounded-lg py-2 "
                  onClick={() => {
                    setIsUserAdd(false);
                    setUserCheck(0);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="RussoOne bg-[#800080] text-white w-[5rem] rounded-lg py-2 "
                  onClick={onCreateUser}
                >
                  Create
                </button>
              </div>
            </form>
          )}
        </div>
      </div>

      <div className="w-[calc(100vw-16rem)] flex flex-col items-center justify-center pt-6 max536:pt-0 gap-10  max1050:w-screen">
        {isUserAdd && userCheck === 2 && (
          <form className="flex flex-wrap gap-6 w-[90%] Sansita">
            <input
              required
              placeholder="Name"
              className="bg-[#ffffff79] text-[#0008] RussoOne px-4 py-1 rounded-lg w-[13rem]"
              type={"text"}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <input
              required
              placeholder="Email Address"
              className="bg-[#ffffff79] text-[#0008] RussoOne px-4 py-1 rounded-lg w-[13rem]"
              type={"email"}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />

            <input
              required
              className="bg-[#ffffff79] text-[#0008] RussoOne px-4 py-1 rounded-lg w-[13rem]"
              placeholder="Phone Number"
              type={"number"}
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
            {/*  <input
              className="bg-[#ffffff79] text-[#0008] RussoOne px-4 py-1 rounded-lg w-[13rem]"
              placeholder="Status"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            />  */}
            <select
              className="bg-[#ffffff79] text-[#0008] RussoOne px-4 py-1 rounded-lg w-[13rem]"
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              value={status}
            >
              <option value={"Active"}>Active</option>
              <option value={"InActive"}>InActive</option>
            </select>
            <input
              required
              className="bg-[#ffffff79] text-[#0008] RussoOne px-4 py-1 rounded-lg w-[13rem]"
              placeholder="Balance"
              type={"number"}
              value={balance}
              onChange={(e) => {
                setBalance(e.target.value);
              }}
            />
            <div className="flex gap-3 w-full items-center justify-center">
              <button
                className="RussoOne bg-[#800080] text-white w-[5rem] rounded-lg py-2 "
                onClick={() => {
                  setIsUserAdd(false);
                  setUserCheck(0);
                }}
              >
                Cancel
              </button>
              <button
                className="RussoOne bg-[#800080] text-white w-[5rem] rounded-lg py-2 "
                onClick={onUpdateUser}
              >
                Update
              </button>
            </div>
          </form>
        )}
        <div
          className={`w-[90%] bg-[#eceaeaa1] mb-[5rem] max536:w-[100%] rounded-3xl p-3 flex flex-col items-center max1050:w-[94vw]`}
        >
          <h2 className=" text-[1.4rem] max536:mb-3 max536:text-[1.7rem] RussoOne font-thin max536:text-white">
            Members List
          </h2>
          <ul className="w-[100%] h-[calc(100vh-14rem)] flex flex-col  max536:bg-[#eceaeaa1] rounded-3xl items-center justify-center pt-6 max536:gap-3 max536:h-[calc(100vh-16rem)] ">
            <li
              className={`w-[100%] flex flex-col items-center justify-center p-2  max536:pt-5 max536:rounded-2xl`}
            >
              <div className="flex w-[96%] max1050:w-[100%] justify-between  mb-3 font-bold">
                <p className="w-[20%] overflow-hidden  max536:hidden ">Name</p>
                {/* <p className="w-[40%] text-left overflow-hidden  max536:hidden ">
                Details
              </p> */}
                <p className="w-[30%] overflow-hidden  max536:hidden ">Email</p>
                <p className="w-[20%] overflow-hidden  max536:hidden ">Phone</p>
                <p className=" w-[5.5rem] max536:hidden ">Attendance</p>
                <p className=" max536:hidden w-[4rem] ">Due</p>
                <img
                  src={UserNamePng}
                  alt=""
                  className="min536:hidden w-10 h-10"
                />
                <img
                  src={DetailsPng}
                  alt=""
                  className="min536:hidden w-10 h-10 "
                />
                <img
                  src={AttendancePng}
                  alt=""
                  className="min536:hidden w-10 h-10 "
                />
                <img src={DuePng} alt="" className="min536:hidden w-10 h-10" />
              </div>
            </li>
            <div className="overflow-auto max536:w-[96%] flex flex-col gap-4 w-[100%] h-[100%]">
              {Ctx.userList
                .filter(user => user.userType === 'member')  // Filter users by userType
                .slice(startIndex, endIndex)
                .map((user, i) => {
                  return (
                    <li
                      key={user.cognitoId}
                      className={`w-[100%] flex flex-col items-center justify-center p-2 max536:bg-[#eceaeaa1]  max536:pt-6 max536:rounded-2xl Sansita max536:text-[0.8rem]`}
                    >
                      <div className="flex w-[96%] max1050:w-[100%] justify-between max1050:justify-between mb-5 relative">
                        <p className="w-[20%] overflow-auto">{user.userName}</p>
                        <p className="w-[40%] text-left overflow-auto max1050:text-[0.8rem] pl-[1rem] min536:hidden">
                          {user.emailId} / {user.phoneNumber}
                        </p>
                        <p className="w-[30%] text-left overflow-auto max1050:text-[0.8rem] max536:hidden">
                          {user.emailId}
                        </p>
                        <p className="w-[20%] text-left overflow-auto max1050:text-[0.8rem]  max536:hidden">
                          {user.phoneNumber}
                        </p>
                        <p className="overflow-hidden w-[3.7rem]  ">
                          {user.attandance}
                        </p>
                        {/* <p className="w-[3.4rem] mr-4   rounded px-2 bg-[#800080] max-h-[1.8rem] self-center flex justify-center items-center max536:mr-0 text-white">
                      12/12
                    </p> */}
                        <p className="w-[3.4rem] mr-4 rounded px-2 max536:mr-0 overflow-hidden">
                          {user.balance > 0 ? 0 : `₹${-user.balance}`}
                        </p>
                        <AiFillEdit
                          onClick={() => {
                            setIsUserAdd(true);
                            setCognitoId(user.cognitoId);
                            setName(user.userName);
                            setEmail(user.emailId);
                            setPhoneNumber(user.phoneNumber);
                            setStatus(user.status);
                            setBalance(user.balance);
                            setUserCheck(2);
                          }}
                          className="absolute top-0 right-2 cursor-pointer"
                        />
                        {/* <AiOutlineLink
                        className="absolute top-0 -right-6 cursor-pointer"
                        onClick={() => {
                          sendReminder(user.cognitoId);
                        }}
                      /> */}
                        <p
                          className="absolute top-0 -right-6 cursor-pointer text-blue-600"
                          onClick={() => {
                            sendReminder(user.cognitoId);
                          }}
                        >
                          Pay
                        </p>
                      </div>
                    </li>
                  );
                })}
            </div>
            <div className="flex items-end justify-end ">
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(event, value) => setCurrentPage(value)}
              />
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UsersList;
