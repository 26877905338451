import React, {useEffect} from "react";
import Card from "react-bootstrap/Card";
import "./Instructor.css";
import instructor from "../Utils/ODISSI/Instructor/instructor.jpg";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Home/Footer";

const Instructor = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <div className="flex flex-col items-center w-full h-[100%] ">
      <NavBar />
      <div className="flex-col items-center instructor-contanier w-full h-screen ">
        {/*<LinkContainer to="/Signup">
          <div className="instructor-headline">Join us as an Instructor</div>
  </LinkContainer> */}
        {/* <a href="https://forms.gle/C8WFVgrQmC9kzQhb6" rel="noreferrer" target="_blank" style={{color:"#ffffff"}}>
        <div className="instructor-headline">
          Join us as an Instructor >>>
        </div>
      </a> */}
        <div className="flex flex-wrap gap-10 ml-6 row justify-center h-full">
          <div className="inst-card col-xs-3 col-lg-3 col-md-12">
            <Card
              className="Box"
              style={{
                backgroundImage: `url(${instructor})`,
                position: "relative",
                zIndex: -2,
              }}
            >
              <div className=""></div>
              <div className="instructor-card-text flex flex-col items-center">
                <h4 className="text-[1.5rem] font-bold"> Narthu Balaraju</h4>
                <h6>Master Choreographer</h6>
              </div>
            </Card>
          </div>
         
              
       {/*   <div className="inst-card col-lg-3 col-md-12">
            <Card
              className="Box"
              style={{
                backgroundImage: `url(${Sophia})`,
                position: "relative",
                zIndex: -2,
              }}
            >
              <div className="overlay"></div>
              <div className="instructor-card-text flex flex-col items-center">
                <h4 className="text-[1.3rem] font-semibold">Sophia</h4>
                <h6> Pro Instructor</h6>
              </div>
            </Card>
          </div>
          <div className="inst-card col-lg-3 col-md-12">
            <Card
              className="Box"
              style={{
                backgroundImage: `url(${Ethan})`,
                position: "relative",
                zIndex: -2,
              }}
            >
              <div className="overlay"></div>
              <div className="instructor-card-text flex flex-col items-center">
                <h4 className="text-[1.3rem] font-semibold">Ethan</h4>
                <h6> Pro Instructor</h6>
              </div>
            </Card>
          </div>
          <div className="inst-card col-lg-4 col-md-12">
            <Card
              className="Box"
              style={{
                backgroundImage: `url(${Lily})`,
                position: "relative",
                zIndex: -2,
              }}
            >
              <div className="overlay"></div>
              <div className="instructor-card-text flex flex-col items-center">
                <h4 className="text-[1.3rem] font-semibold">Lily</h4>
                <h6> Pro Instructor</h6>
              </div>
            </Card>
          </div>
          <div className="inst-card col-lg-4 col-md-12">
            <Card
              className="Box"
              style={{
                backgroundImage: `url(${William})`,
                position: "relative",
                zIndex: -2,
              }}
            >
              <div className="overlay"></div>
              <div className="instructor-card-text flex flex-col items-center">
                <h4 className="text-[1.3rem] font-semibold">William</h4>
                <h6> Pro Instructor</h6>
              </div>
            </Card>
          </div>
          <div className="inst-card col-lg-4 col-md-12">
            <Card
              className="Box"
              style={{
                backgroundImage: `url(${James})`,
                position: "relative",
                zIndex: -2,
              }}
            >
              <div className="overlay"></div>
              <div className="instructor-card-text flex flex-col items-center">
                <h4 className="text-[1.3rem] font-semibold">James</h4>
                <h6> Pro Instructor</h6>
              </div>
            </Card>
          </div>
          <div className="inst-card col-lg-4 col-md-12">
            <Card
              className="Box"
              style={{
                backgroundImage: `url(${Charlotte})`,
                position: "relative",
                zIndex: -2,
              }}
            >
              <div className="overlay"></div>
              <div className="instructor-card-text flex flex-col items-center">
                <h4 className="text-[1.3rem] font-semibold">Charlotte</h4>
                <h6> Pro Instructor</h6>
              </div>
            </Card>
          </div>
          <div className="inst-card col-lg-4 col-md-12">
            <Card
              className="Box"
              style={{
                backgroundImage: `url(${Ava})`,
                position: "relative",
                zIndex: -2,
              }}
            >
              <div className="overlay"></div>
              <div className="instructor-card-text flex flex-col items-center">
                <h4 className="text-[1.3rem] font-semibold">Ava</h4>
                <h6> Pro Instructor</h6>
              </div>
            </Card>
          </div>
          <div className="inst-card col-lg-4 col-md-12">
            <Card
              className="Box"
              style={{
                backgroundImage: `url(${Oliver})`,
                position: "relative",
                zIndex: -2,
              }}
            >
              <div className="overlay"></div>
              <div className="instructor-card-text flex flex-col items-center">
                <h4 className="text-[1.3rem] font-semibold">Oliver</h4>
                <h6> Pro Instructor</h6>
              </div>
            </Card>
            </div>   */}
        </div>
      </div>
      </div>
      <Footer />
    </>

  );
};

export default Instructor;
